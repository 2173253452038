














import Vue from 'vue'
import { mapGetters } from 'vuex'
import Organization from '../../../../store/models/Organization'

export default Vue.extend({
  data() {
    return {
      columns: [
        { label: 'Nom', field: 'name', searchable: true },
        { label: 'Représentant', field: 'representativeName' },
        { label: 'Siège', field: 'address' },
        { label: 'Adresse électronique', field: 'email' },
        { label: 'Téléphone', field: 'contact' },
        { label: 'Administrateur', field: 'admin.fullname' }
      ]
    }
  },

  computed: {
    ...mapGetters('product', ['getCurrentProduct']),
    items() {
      console.log(this.getCurrentProduct.sfdConcern)
      return Organization.query()
        .where('type', 'sfd')
        .whereIdIn(this.getCurrentProduct.sfdConcern)
        .with('admin')
        .all()
    }
  },

  created() {
    Organization.api().fetch()
  }
})
